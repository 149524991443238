import {post, get} from '../apiService';

export const postSignUp = body => {
    return post('/auth/sign-up', body);
};
export const postSignIn = body => {
    return post('/auth/sign-in', body);
};
export const postUpdateToken = body => {
    return post('/auth/update-token', body);
};
export const postUpdateTempToken = body => {
    return post('/auth/sign-in/update-temp-token', body);
};
export const postForgotPassword = body => {
    return post('/forgot-password', body);
};
export const postForgotPasswordCode = ({code, id}) => {
    return post(`/forgot-password/${id}/check-code`, {code});
};
export const postResetPassword = ({code, id, password}) => {
    return post(`/forgot-password/${id}/reset`, {code, password});
};
export const postLogoutUser = body => {
    return post('/auth/logout', body);
};
export const getUserInfo = () => {
    return get('/user/me');
};
export const checkUser = (email) => {
    return post('/auth/check-user', { email });
};
